import React from "react"
import Layout from "../components/layout"
import Banner from "../components/banner"
import Seo from "../components/seo"
import BlogPost from "../components/blogPost"

const BlogPage = () => (
  <Layout>
    <Seo
      title="Articles informatifs sur le SEO et le référencement naturel - Marie-Luce Beraudo, SEO Alternative"
      description="Consultante SEO basée dans le pays de Gex près de Genève, je réalise des audits SEO, du coaching SEO et des formations SEO."
    />
    <Banner title="Articles rédigés par SEO Alternative"></Banner>
    <div className="blog-details-wrapper pt--70 pb--100 bg-color-white">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <p>Vous trouverez ci-dessous quelques articles que j'ai rédigés en rapport avec le SEO :</p>
          </div>
        </div>
      </div>
    </div>
    <BlogPost></BlogPost>
  </Layout>
)
export default BlogPage
